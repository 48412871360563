import _ from 'lodash';
import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { decrementItem, incrementItem } from 'src/services/member-auth/utils/helper';
import { TFavoriteTypes } from 'src/services/member-auth/interface/member';
import { IArticle } from 'src/interface/section';
export type TChild = {
  id: number | null;
  nameTh: string | null;
  nameEng: string | null;
  image: string | null;
};

export interface IState extends EntityState<string> {
  tags: TChild[];
  categories: TChild[];
  articlesTags: IArticle[];
  articlesCategories: IArticle[];
}
export type TSateSelected = {
  tags: TChild[];
  categories: TChild[];
  articlesTags?: IArticle[];
  articlesCategories?: IArticle[];
};
export interface IReduxMember {
  member: IState;
}

// entity
const dataAdapter = createEntityAdapter<string>();

// initial state
const initialState: IState = {
  tags: [],
  categories: [],
  articlesTags: [],
  articlesCategories: [],
  ...dataAdapter.getInitialState()
};
interface IActionItem {
  data: TChild;
  type: TFavoriteTypes;
}
interface IActionLists {
  data: TChild[];
  type: TFavoriteTypes;
}
interface IActionArticle {
  data: IArticle[];
  type: 'articlesTags' | 'articlesCategories';
}

type CamelizeString<T extends PropertyKey, C extends string = ''> = T extends string
  ? string extends T
    ? string
    : T extends `${infer F}_${infer R}`
    ? CamelizeString<Capitalize<R>, `${C}${F}`>
    : `${C}${T}`
  : T;

export type TMemberTypes = CamelizeString<'tags' | 'categories' | 'articles_tags' | 'articles_categories'>;

export const member = createSlice({
  name: 'member',
  initialState: _.cloneDeep(initialState),
  reducers: {
    initialNews: (state, action: PayloadAction<IActionArticle>) => {
      state[action.payload.type] = action.payload.data;
    },
    initialLists: (state, action: PayloadAction<IActionLists>) => {
      // console.log('#1 initialLists');
      // if (!_.isEmpty(action?.payload?.data)) {
      state[action.payload.type] = [...action.payload.data];
      // }
    },
    increment: (state, action: PayloadAction<IActionItem>) => {
      // console.log('#1 increment');
      state[action.payload.type] = incrementItem(state[action.payload.type], action.payload.data);
    },
    specificDecrement: (state, action: PayloadAction<IActionItem>) => {
      // console.log('#1 specificDecrement');
      state[action.payload.type] = decrementItem(state[action.payload.type], action.payload.data);
    }
  }
});
export const { increment, specificDecrement, initialNews, initialLists } = member.actions;
export default member.reducer;
