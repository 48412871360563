import { useEffect } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import numeral from 'numeral';
import _ from 'lodash';

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
export const timestamp = () => {
  return dayjs(Date.now()).format('YYYY-MM-DD HH:MM:ss');
};
export function dropSomeField(obj: any, fieldArray: any) {
  const object = { ...obj };
  for (let index = 0; index < fieldArray.length; index++) {
    delete object[fieldArray[index]];
  }
  return object;
}
export const escapeChar = (str: any) => {
  if (!str) return null;
  return `${str}`.replace(/[\\]/g, '').replace(/["]/g, '\\$&');
};

export function rewriteCid(_txt: any) {
  switch (_txt) {
    case 'video':
      return '/category/video';
    case 'column':
      return '/category/column';
    default:
      return '/category/news';
  }
}
export function rewriteTxt(_txt: any) {
  if (_txt.indexOf('.') !== -1) return 'news';
  switch (_txt) {
    case 'category':
    case 'rss':
      return 'news';
    case 'column':
      return 'column';
    default:
      return _txt;
  }
}

export const escapeCharTitle = (str: any) => {
  if (!str) return null;
  return `${str}`.replace(/["]/g, '');
};

export const htmlEntities = (str: any) => {
  if (!str) return null;
  return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&apos;');
};

export const convertObjPath = (_obj: any) => {
  if (!_obj || Object.keys(_obj).length < 1) return '';
  const entries = Object.entries(_obj);
  const itemData = encodeURI(`?${entries.join('&').split(',').join('=')}`);
  // const itemData = `?${entries[0].shift(0)}=${encodeURIComponent(entries.join('&').split(',').join('='))}`;
  // const itemData = `?${entries[0].shift(0)}=${encodeURI(entries.join('&').split(',').join('='))}`;
  return itemData;
};

export const convertObjPathNoneEncode = (_obj: any) => {
  if (!_obj || Object.keys(_obj).length < 1) return '';
  const entries = Object.entries(_obj);
  const itemData = `?${entries.join('&').split(',').join('=')}`;
  return itemData;
};

export function convertBadge(data: any, format: boolean): string {
  if (format) return `${data?.section?.th}/${data?.subCategory?.th}`;
  return data?.subCategory?.th ? data?.subCategory?.th : data?.section?.th;
}
export const convertArrPathLottery = (_obj: any) => {
  if (!Array.isArray(_obj.lottos) || _obj.lottos?.length === 0) return null;
  const feild = Object.keys(_obj)[0];
  const itemData = `?${feild}[]=${_obj.lottos.join(`&${feild}[]=`)}`;
  return itemData;
};

export const convertArrPath = (_obj: any, field: any): any => {
  let result = '';
  if (!_.isArray(_obj)) return null;

  if (_obj?.length > 0) {
    result = `?${field}[]=${_obj.join(`&${field}[]=`)}`;
  } else {
    result = `?${field}=${_obj[0]}`;
  }
  return result;
};
// TODO : check children is empty
// ? (childrenEmpty('  ')) return true
// ? (childrenEmpty(null)) return false
// ? (childrenEmpty(123)) return false
// ? (childrenEmpty()) return false
export function childrenEmpty(_child: any) {
  return !`${_child}`.split(' ').join('');
}

// TODO Wait API
export function delay(_ms: any) {
  return new Promise(resolve => {
    setTimeout(resolve, _ms);
  });
}
export const getWidthDimension = () => {
  if (typeof window !== 'undefined') {
    const { innerWidth: width }: any = window;
    return {
      width: width || 0
    };
  }
  return null;
};

export const splitRote = (_route: any, special?: boolean) => {
  if (!_route) return '';
  if (_route.route === '/404') return '';
  if (special) return '/themes-001';
  const stepI = _route.route?.split('/')[1] || _route.url?.split('/')[1] || '';
  const stepII = stepI.split('-');
  const stepIII = stepII[0] === 'themes' ? '/' + stepI : '';

  return stepIII;
};
// TODO : clean action in menu
export function useOnClickOutsideExpand(ref: any, handler: any) {
  useEffect(() => {
    const listener = (event: any) => {
      // if (!ref.current || ref?.current.contains(event.target)) {
      if (!ref.current) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mouseleave', listener);

    return () => {
      document.removeEventListener('mouseleave', listener);
    };
  }, [ref, handler]);
}

// TODO : actionSidebar
export function toggleSidebar(_toggle: boolean) {
  if (_toggle) {
    document.getElementById('__next')?.classList.remove('menu-inactive');
    document.getElementById('__next')?.classList.add('menu-active');
    document.getElementById('__next')?.classList.add('header-switch-hide');
    document.getElementById('menu-switch')?.classList.add('hidden');
  } else {
    document.getElementById('__next')?.classList.remove('menu-active');
    document.getElementById('__next')?.classList.add('menu-inactive');
    document.getElementById('menu-switch')?.classList.remove('hidden');
  }
}

/* ------------------------------- HOW TO USE ------------------------------- */
// TODO : Import to css globals and set id in component menu-mobile
// .menu-active {
//   height: 100vh;
//   position: fixed;
//   transform: translate3d(320px, 0, 0);
//   transition: all .4s ease;
// }
// .menu-inactive {
//  transform: translate3d(0, 0, 0);
// transition: all .4s ease;
// }

// TODO : DATE NOW
// dateNow();
export function useDateNow() {
  const now = dayjs(new Date());
  const christianYear = now.format('YYYY');
  const buddhishYear = (parseInt(christianYear) + 543).toString();
  const today = now.locale('th').format('LLLL').replace(christianYear, buddhishYear);
  return today;
}

// TODO : DATE UPDATE
// useDateUpdate()
export function useDateUpdate(_date?: Date, _showTime?: boolean) {
  if (!_date) return null;
  const _format = _showTime === true ? 'DD MMM YYYY |  HH:mm น.' : 'DD MMM YYYY';
  return dayjs(_date).locale('th').add(543, 'year').format(_format);
}
// export function useDateUpdate (_date?: Date | string): string | null {
//   if (!_date) return null;
//   return dayjs(_date).locale('th').add(543, 'year').format('DD MMM YYYY');
// }
// useTimeUpdate()
export function useTimeUpdate(_date: number) {
  if (!_date) return null;
  return dayjs(_date).locale('th').add(543, 'year').format('HH:mm');
}

// // TODO : RENDER PAGEVIEWS
// // usePageViews(data);
export function usePageViews(_pageviews: number) {
  const result = _pageviews > 999 ? numeral(+_pageviews).format('0.0 a') : _pageviews;
  // console.log({ result });
  return result;
}

// // TODO : SET  NUMERAL
// export function setNumeral (num) {
//   return numeral(num).format('0,0');
// }

// TODO : ModifyMenu
// ModifyMenu(menu);
// Check nameEng('Lottery')
export function ModifyMenu(_array: any[]) {
  if (!Array.isArray(_array) || !_array) return [];
  return (
    Array.isArray(_array) &&
    _array.reduce((acc, cur) => {
      const indexEl = _array.findIndex(el => el.nameEng.indexOf('lottery') && cur.nameEng.indexOf('lottery'));
      if (indexEl !== -1) {
        return [...acc, { ...cur }];
      } else {
        return [...acc, { ...cur, sub: [] }];
      }
    }, [])
  );
}

export function convertValue(_arr: [] | any, _key: string | any) {
  return _arr.filter((el: {} | any) => el?.key.split('-')[0] === _key);
}

export function GetValueObjectFromArray(arr: any[], key: string, keyOfObj: string) {
  if (_.isEmpty(arr)) return null;
  return key === 'topic' ? _.find(arr, { topic: keyOfObj }) : _.find(arr, { key: keyOfObj })?.value;
}

export const timeAgo = (_date?: Date | string): string | null => {
  if (!_date) return null;
  const dateNow = dayjs(_date).locale('th');
  const diffStr = dateNow.from(new Date());
  return diffStr;
};

export function htmlToPlainText(html: any) {
  const parser = new DOMParser();
  const dom = parser.parseFromString(html, 'text/html');
  return dom.body.textContent || '';
}