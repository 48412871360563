import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
export const useDesktop = () => useMediaQuery({ query: '(min-width: 1024px)' });
export const useTablet = () => useMediaQuery({ query: '(max-width: 1023px)' });
export const useTablet768px = () => useMediaQuery({ query: '(max-width: 768px)' });
export const useTabletPc = () => useMediaQuery({ query: '(max-width: 1023px) and (min-width: 481px)' });
export const useMobilePhone = () => useMediaQuery({ query: '(max-width: 480px)' });

const useResponsive = () => {
  const [isClient, setIsClient] = useState(false);

  const isMobileMini = useMediaQuery({
    maxWidth: '690px'
  });
  const isMobile = useMediaQuery({
    maxWidth: '767px'
  });

  const isTablet = useMediaQuery({
    maxWidth: '900px'
  });

  const isLaptop = useMediaQuery({
    minWidth: '767px'
  });

  const isDesktop = useMediaQuery({
    minWidth: '1024px'
  });

  useEffect(() => {
    if (typeof window !== 'undefined') setIsClient(true);
  }, []);

  return {
    isDesktop: isClient ? isDesktop : true,
    isLaptop: isClient ? isLaptop : true,
    isTablet: isClient ? isTablet : false,
    isMobile: isClient ? isMobile : false,
    isMobileMini: isClient ? isMobileMini : false
  };
};

export default useResponsive;
