import parse from 'html-react-parser';
import _ from 'lodash';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useEffect, useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { Provider } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import 'slick-carousel/slick/slick-theme.scss';
import 'slick-carousel/slick/slick.scss';
import 'src/assets/sass/style.scss';
import DynamicAds, { KEY_ADS_POSITION } from 'src/components/ads/dynamicAds';
import { CLOSE_ADS_HRAD_ENV } from 'src/constants';
import { store } from 'src/redux/store';
import { GET } from 'src/utils/service';

const PageError = dynamic(import('./404'));

function MyApp({ Component, pageProps: { ...pageProps } }: AppProps | any) {
  const [scriptBrowser, setScriptBrowser]: any = useState(null);
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1024px)'
  });

  useEffect(() => {
    if (CLOSE_ADS_HRAD_ENV === 'true') {
      (async () => {
        const scriptBrowser = await GET('/api/headerAds');
        if (!_.isEmpty(scriptBrowser)) {
          setScriptBrowser(scriptBrowser);
        }
      })();
    }
  }, []);

  if (pageProps.statusCode >= 400) {
    return <PageError sessionSevId={null} />;
  }

  const [toggle, setToggle] = useState(false);
  const filterLightBox = _.filter(pageProps?.ads, i => i.position === 'light-box');

  const handlerToggle = () => {
    setToggle(!toggle);
  };

  useEffect(() => {
    !_.isEmpty(filterLightBox?.[0]?.slotId) && !toggle
      ? document.getElementById('__next')?.classList?.add('show-ttp-light-box')
      : document.getElementById('__next')?.classList?.remove('show-ttp-light-box');
    // if (toggle) {
    //   document.getElementById('__next')?.classList?.add('show-ttp-light-box');
    // } else {
    //   document.getElementById('__next')?.classList?.remove('show-ttp-light-box');
    // }
  }, [toggle]);
  return (
    <>
      <>
        <Provider store={store}>
          <Head>
            <meta name='viewport' content='width=device-width, initial-scale=1' />

            {/* --------------------------------- Truehit -------------------------------- */}
            <script
              dangerouslySetInnerHTML={{
                __html: `
                    (function() {
                      var ga1 = document.createElement('script'); 
                      ga1.type = 'text/javascript';
                      ga1.async = true;
                      ga1.src = "//lvs.truehits.in.th/dataa/t0032272.js";
                      var s = document.getElementsByTagName('script')[0]; 
                      s.parentNode.insertBefore(ga1, s);
                    })();
                    `
              }}
            />
            {/* ----------------------------- ScriptBrowser ------------------------------------ */}
            {!isDesktopOrLaptop && !_.isEmpty(scriptBrowser) ? parse(`${scriptBrowser?.header_mobile}`) : ''}
            {isDesktopOrLaptop && !_.isEmpty(scriptBrowser) ? parse(`${scriptBrowser?.header_desktop}`) : ''}
          </Head>
          {!_.isEmpty(filterLightBox?.[0]?.slotId) && !toggle && (
            <div id='ads-nlightbox' className='ttp-overlay-light-box' onClick={handlerToggle}>
              <div className='ttp-light-box-wrapper'>
                <div className='ttp-light-box-close' onClick={handlerToggle}>
                  <AiOutlineCloseCircle />
                </div>
                {!_.isEmpty(pageProps.ads) && <DynamicAds position={KEY_ADS_POSITION.LIGHTBOX} data={pageProps.ads} />}
              </div>
            </div>
          )}
          <Component {...pageProps} />
        </Provider>
      </>
    </>
  );
}

export default MyApp;
