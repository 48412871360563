import { createSlice } from '@reduxjs/toolkit';

interface IAddressPair {
  id?: number;
  nameTH?: string;
}
interface IAddressObject {
  province: IAddressPair;
  district: IAddressPair;
  subdistrict: IAddressPair;
}
interface initJsonData {
  jsonProvince?: string;
  jsonDistrict?: string;
  jsonSubdistrict?: string;
}
// eslint-disable-next-line no-redeclare
const initJsonData: initJsonData = {};
const initData: IAddressObject = {
  province: {},
  district: {},
  subdistrict: {}
};
export const address = createSlice({
  name: 'address',
  initialState: {
    jsonData: initJsonData,
    value: initData
  },
  reducers: {
    updateJsonProvince: (state, action) => {
      state.jsonData.jsonProvince = action.payload;
    },
    updateJsonDistrict: (state, action) => {
      state.jsonData.jsonDistrict = action.payload;
    },
    updateJsonSubdistrict: (state, action) => {
      state.jsonData.jsonSubdistrict = action.payload;
    },
    updateProvince: (state, action) => {
      state.value.province.id = action.payload?.id;
      state.value.province.nameTH = action.payload?.nameTH;
    },
    updateDistrict: (state, action) => {
      state.value.district.id = action.payload?.id;
      state.value.district.nameTH = action.payload?.nameTH;
    },
    updateSubDistrict: (state, action) => {
      state.value.subdistrict.id = action.payload?.id;
      state.value.subdistrict.nameTH = action.payload?.nameTH;
    }
  }
});
export const { updateProvince, updateDistrict, updateSubDistrict, updateJsonProvince, updateJsonDistrict, updateJsonSubdistrict } = address.actions;
export default address.reducer;
